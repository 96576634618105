import { PanelResolveType } from '@wix/platform-editor-sdk';
import { EXPERIMENTS } from '@wix/communities-blog-client-common';
import reinstallBlogIllustration from '../assets/reinstall-blog.png';
import { blogAppDefId } from '../external/common/constants/apps';
import translation from '../external/common/services/translation';
import { EditorAppContext } from '../types/editor-app-context.type';

const { translate } = translation;

export async function handleBlogInstallTimeout(context: EditorAppContext) {
  if (
    context.flowAPI.environment.isADI ||
    !context?.flowAPI.experiments.enabled(EXPERIMENTS.INSTALLATION_FIXER)
  ) {
    return;
  }

  const result = await context.sdk.editor.openConfirmationPanel(
    context.appToken,
    {
      illustration: reinstallBlogIllustration,
      shouldShowIllustration: true,
      headerText: translate('installation-timeout.title'),
      descriptionText: translate('installation-timeout.description'),
      mainActionText: translate('installation-timeout.mainAction'),
      secondaryActionText: translate('installation-timeout.secondaryAction'),
    },
  );

  if (result === PanelResolveType.MAIN_ACTION) {
    await context.sdk.application.uninstall(context.appToken, {
      openConfirmation: false,
    });

    await context.sdk.tpa.add.application(context.appToken, {
      appDefinitionId: blogAppDefId,
      shouldNavigate: true,
      showPageAddedPanel: true,
    });
  } else if (result === PanelResolveType.SECONDARY_ACTION) {
    await context.sdk.application.uninstall(context.appToken, {
      openConfirmation: false,
    });
  }
}
